import React, { useRef } from 'react'
import './Join.css'
import emailjs from '@emailjs/browser'
import toast, { Toaster } from 'react-hot-toast'

const notify = () => (toast.success('Mail Successfully Send!'))

const Join = () => {

    const form = useRef()

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_avjsz64', 'template_t6b4r5l', form.current, 'e-NlWz3MlXj6B6d9J')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
    };

    return (
        <div className='Join' id='join-us'>
            <div><Toaster /></div>
            <div className="left-j">
                <hr />
                <div>
                    <span className='strokes-text'>READY TO</span>
                    <span>LEVEL UP</span>
                </div>
                <div>
                    <span>YOUR BODY</span>
                    <span className='strokes-text'>WITH US?</span>
                </div>
            </div>
            <div className="right-j">
                <form ref={form} className='email-container' onSubmit={sendEmail}>
                    <input type="email" name='user_email' placeholder='Enter your email address' />
                    <button onClick={notify} className='btn btn-j'>Join Now</button>
                    <Toaster
                        position="top-center"
                        reverseOrder={false}
                    />
                </form>
            </div>
        </div>
    )
}

export default Join
